import React, { FC, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { AccountContentContainer } from './style';
import Card from 'components/Card';
import Modal from 'components/Modal';
import CancelBookingModalBody from '../CancelBookingModalBody';
import CancelReasonModalBody from '../CancelReasonModalBody';
import StyledButton from 'components/Button';
import Dropdown from 'components/Dropdown';
import { useRecoilState } from 'recoil';
import { cancelBookingModalOpenState, cancelReasonModalOpenState, cancelGroupId, cancelBookingId, cancelBookingFee, canceledBookingIdState, canceledGroupIdState } from 'states/ModalStates/atoms';
import {globalMessageState, globalErrorState} from 'states/CommonStates/atoms';
import { useLoadTrips } from 'customHooks/useLoadTrips';
import { CheckInIcon, CheckOutIcon, DownArrowIcon, DownloadIcon } from 'assets/icons';
import {bookingsStatuses} from 'constants/constant';
import AlertMessage from 'components/AlertMessage';
import moment from 'moment';




const OverAllContent: FC = () => {


  const debug = true;

  const history = useHistory()
  const { trips, loading, refetch } = useLoadTrips()
  const [hoveredPosition, setHoveredPosition] = useState('')
  const [openCancelBookingModal, setOpenCancelBookingModal] = useRecoilState(cancelBookingModalOpenState)
  const [openCancelReasonModal, setOpenCancelReasonModal] = useRecoilState(cancelReasonModalOpenState)
  const [cancelModalGroupId, setCancelModalGroupId] = useRecoilState(cancelGroupId)
  const [cancelModalBookingId, setCancelModalBookingId] = useRecoilState(cancelBookingId)
  const [cancelModalBookingFee, setCancelModalBookingFee] = useRecoilState(cancelBookingFee)


  // const [sucsessAlertMessage, setSucsessAlertMessage] = useState(false);
  // const [sucsessAlertMessageText, setSucsessAlertMessageText] = useState('');

  // const [errorAlertMessage, setErrorAlertMessage] = useState(false);
  // const [errorAlertMessageText, setErrorAlertMessageText] = useState('');

  const [messageState, setMessageState] = useRecoilState(globalMessageState);
  const [errorState, setErrorState] = useRecoilState(globalErrorState);

  const closeMessage = () => setMessageState({ isVisible: false, text: '' });
  const closeError = () => setErrorState({ isVisible: false, text: '' });


  const [canceledBookingId, setCanceledBookingId] = useRecoilState(canceledBookingIdState);
  const [canceledGroupId, setCanceledGroupId] = useRecoilState(canceledGroupIdState);

  useEffect(() => {
    if (canceledBookingId || canceledGroupId) {
      if (debug) {
        console.log("Refetch bookings...")
      }
      refetch();
    }
    setCanceledBookingId ('')
    setCanceledGroupId ('')

  }, [canceledBookingId, canceledGroupId]);

  
  useEffect(() => {
  if (debug) {
    if (trips) {
      console.log("Current trips: ", trips);
    }
  }
}, [trips]);



  const handleDownload = (url: string ) => {
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'receipt.pdf'); // Specify the file name
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const tripsArray = Object.keys(trips).map((groupId, index) => {


// Determinating group status BEGIN:

// Vadim: If we have 3 bookings in group, first status is "cheched-in", another is "Panding", and thid one is "checked Out", what should I show in table with group booking? Confirmed || checked In || checked Out? 
// Shamim: The status of the group booking will be Checked-in in my opinion, as we already have someone in that status. Once everyone has checked out, the status will be updated to Checked-out. 

// Vadim: if status in database is CHECKED_IN but name and phone number are missing for the other room in the group booking, what status I should show "Incomplete" or "Checked in"?
//        same question with CHECKED_OUT status?
// Shamim: You should use  Checked In and  Checked out here.



    
// Initial status:
    let currentGroupBookingStatus = "CONFIRMED";

    let checkedOutStatusCounter = 0;
    let expiterdStatusCounter = 0;
    let cancelledStatusCounter = 0;
    let incompliteBookings = 0;

    trips[groupId].bookings.forEach(currntBooking => {
      
      switch (currntBooking.status) {
        case "CHECKED_OUT":
          checkedOutStatusCounter++;
          break;
        case "CHECKED_IN":
          // If one CHECKED_IN -> group CHECKED_IN
          currentGroupBookingStatus = "CHECKED_IN";
          break;
        case "EXPIRED":
          expiterdStatusCounter++;
          break;
        case "CANCELLED":
          cancelledStatusCounter++;
          break;
        case "PENDING":

        if (!(currntBooking.fullName && currntBooking.phoneNumber)) {
          incompliteBookings++;
        }

        break;
      }

      // If everyone CHECKED_OUT -> group CHECKED_OUT
      if (checkedOutStatusCounter === trips[groupId].bookings.length) {
        currentGroupBookingStatus = "CHECKED_OUT";
      }

      // If everyone EXPIRED -> group EXPIRED
      if (expiterdStatusCounter === trips[groupId].bookings.length) {
        currentGroupBookingStatus = "EXPIRED";
      }

      // If everyone CANCELLED -> group CANCELLED
      if (cancelledStatusCounter === trips[groupId].bookings.length) {
        currentGroupBookingStatus = "CANCELLED";
      }

      // If only CANCELLED and EXPIRED in group -> group EXPIRED
    
      // Vadim: If one booking in grop is CANCELLED and one is EXPIRED what group status will be?
      // Shamim: I think we should use EXPIRED for this one since we will still charge the guest.
      if (
        (cancelledStatusCounter + expiterdStatusCounter === trips[groupId].bookings.length) &&
        cancelledStatusCounter > 0 &&
        expiterdStatusCounter > 0
      ) {
        currentGroupBookingStatus = "EXPIRED";
      }


    });

    // If thre is INCOMPLETE 1 or more bookings -> group INCOMPLETE
    if (currentGroupBookingStatus === 'CONFIRMED' && incompliteBookings > 0) {
      currentGroupBookingStatus = 'INCOMPLETE';
    }
    
    
    // Determinating group status END



    const dropdownOptions = [
      {label: 'Booking Details', function: ()=> history.push(`/account/bookings/${groupId}`)},
    ]

    if (
      currentGroupBookingStatus === "INCOMPLETE" &&
      trips[groupId].bookings.length > 1
    ) {
      dropdownOptions.push({
        label: "Add Guest Info",
        function: () =>
          history.push(`/account/bookings/addGuestInfo/${groupId}`),
      });
    }

    if (
      currentGroupBookingStatus !== "CHECKED_OUT" &&
      currentGroupBookingStatus !== "CHECKED_IN" &&
      currentGroupBookingStatus !== "CANCELLED" &&
      currentGroupBookingStatus !== "EXPIRED"
    ) {
      if (trips[groupId].bookings.length > 1) {
        dropdownOptions.push({
          label: "Cancel Booking",
          function: () =>
            history.push(`/account/bookings/CancelGroupBooking/${groupId}`),
        });
      } else {
        dropdownOptions.push({
          label: "Cancel Booking",
          function: () => {
            setCancelModalGroupId(groupId);
//            setCancelModalBookingId(trips[groupId].bookings[0]?.bookingId);
            setCancelModalBookingFee(trips[groupId]?.bookings?.[0]?.roomBookings?.[0]?.pricePerNight!.toString());
            setOpenCancelBookingModal(true);
          },
        });
      }
    }

    // No booking, no group
    if (trips[groupId].bookings.length === 0) {
      return null
    }

    else  return (

      <div key={index} className='trip'>
        <div className={'status ' + (bookingsStatuses[currentGroupBookingStatus]?.className || 'checkedIn') }>{bookingsStatuses[currentGroupBookingStatus]?.text || 'Checked in'}</div>
        <div className='reservationCode'>{trips[groupId].primaryBookingCode}</div>
        <div className='rooms'>{trips[groupId].bookings.length}</div>
        <div className='dates'>
          <div className='checkIn'>
            <CheckInIcon />
            {
              moment(trips[groupId].bookings?.reduce((min, current) => {
                return current.checkInDate < min? current.checkInDate: min;
              }, trips[groupId].bookings[0]?.checkInDate)).format('ddd DD MMM, h:mm A')
            }
          </div>
          <div className='checkOut'>
            <CheckOutIcon />
            {
              moment(trips[groupId].bookings?.reduce((max, current) => {
                return current.checkOutDate > max? current.checkOutDate: max;
              }, trips[groupId].bookings[0]?.checkOutDate)).format('ddd DD MMM, h:mm A')
            }
          </div>
        </div>
        <div className='total'>
          {trips[groupId].bills.reduce((acc, bill) => acc + (bill.total || 0), 0).toFixed(2)}
        </div>
        {(trips[groupId].status==='CHECKED_OUT')?
          <div className='button'>
            <StyledButton
              width='118px'
              height='48px'
              onClick={() => handleDownload(trips[groupId].receipt)}
              startIcon={<DownloadIcon />}
            >
              Receipt
            </StyledButton>
          </div>:
          <div className='button'>
            <StyledButton
              width='104px'
              height='48px'
              onClick={() => history.push(`/account/bookings/${groupId}`)}
              margin='0 1px 0 0'
            >
              Manage
            </StyledButton>
            <div
              onMouseEnter={()=>setHoveredPosition(groupId)}
              onMouseLeave={()=>setHoveredPosition('')}
              style={{position: 'relative'}}
            >
              <StyledButton
                width='32px'
                height='48px'
              >
                <DownArrowIcon />
              </StyledButton>
              <Dropdown
                isActive={groupId===hoveredPosition}
                width='137px'
                options={dropdownOptions}
              />
            </div>
          </div>
        }
      </div>
    )
  })

  return (
    <AccountContentContainer>

            <AlertMessage
                showMessage={messageState.isVisible}
                //messageType = 'error'
                onClose={() => closeMessage()} // Changing state on close
            >
                {messageState.text}
            </AlertMessage>
            
            <AlertMessage
                showMessage={errorState.isVisible}
                messageType = 'error'
                onClose={() => closeError()} // Changing state on close
            >
                {errorState.text}
            </AlertMessage>

      <div className="accountBody" 
      >
        {(Object.keys(trips).length === 0) && !loading? (
          <div className='noBooking'>
            <div className='content'>
              <div>Looks like there aren't any bookings here.</div>
              <StyledButton
                width='118px'
                height='48px'
                margin='20px 0 0 0'
                onClick={() => history.push('/')}
              >
                Book Now
              </StyledButton>
            </div>
          </div>
        ): (
          <div className="cardContent">
            <div className="bookingTableHeader">
              <div className='status'>STATUS</div>
              <div className='reservationCode'>CONF #</div>
              <div className='rooms'>ROOMS</div>
              <div className='dates'>CHECK-IN/CHECK-OUT</div>
              <div className='total'>TOTAL PRICE</div>
              <div className='button'>&nbsp;</div>
            </div>
            <div className="bookingTableContent">
              {tripsArray}
            </div>
          </div>
        )}
        <Modal open={openCancelBookingModal} >
          <CancelBookingModalBody />
        </Modal>
        <Modal open={openCancelReasonModal} >
          <CancelReasonModalBody />
        </Modal>
      </div>
    </AccountContentContainer>
  );
};

export default OverAllContent;
