import { atom } from 'recoil';


// Atom to show messages - green alert
export const globalMessageState = atom({
    key: 'globalMessageState',
    default: {
      isVisible: false, 
      text: '',         
    },
  });

// Atom to show errors - red alert
  export const globalErrorState = atom({
    key: 'globalErrorState',
    default: {
      isVisible: false, 
      text: '',         
    },
  });