import React from 'react';
import IntlTelInput from "intl-tel-input/react";
import "intl-tel-input/styles";
import { PhoneNumberInputContainer } from './style';

export type InputFieldContainerProps = {
  className?: string;
  placeholder?: string;
  label?: string;
  labelPosition?: 'left' | 'center';
  labelColor?: string;
  labelSize?: string;
  labelWeight?: string;
  borderColor?: string;
  inputHeight?: string;
  inputTextColor?: string;
  inputFontSize?: string;
  inputFontWeight?: string;
  inputBackgroundColor?: string;
  error?: boolean;
  errorMessage?: string;
  width?: string;
  marginLeft?: string;
  marginBottom?: string;
  isRequired?: boolean;
  initialValue?: string;
  disabled?: boolean;
  onChangeNumber: (e: any) => void;
  setPhoneNumberValidity?: (e: any) => void;
  onBlur?: (e: any) => void;
};

const PhoneNumberInput: React.FC<InputFieldContainerProps> = ({
  className,
  placeholder,
  width,
  label,
  inputBackgroundColor,
  isRequired,
  onChangeNumber,
  setPhoneNumberValidity,
  onBlur,
  initialValue,
  disabled,
  ...layout
}) => {

  return (
    <PhoneNumberInputContainer
      className={className}
      labelPosition={layout.labelPosition}
      labelColor={layout.labelColor}
      labelSize={layout.labelSize}
      labelWeight={layout.labelWeight}
      borderColor={layout.borderColor}
      inputHeight={layout.inputHeight}
      inputTextColor={layout.inputTextColor}
      inputFontSize={layout.inputFontSize}
      inputFontWeight={layout.inputFontWeight}
      inputBackgroundColor={inputBackgroundColor}
      error={layout.error}
      width={width}
      marginLeft={layout.marginLeft}
      marginBottom={layout.marginBottom}
    >
      {label?
        <label>
          {label}
          {isRequired? <span className="required">*</span>: null}
        </label>
      : null
      }
      <IntlTelInput
        initialValue={initialValue || ''} 
        initOptions={{ 
          initialCountry: 'ca',
          placeholderNumberType	: 'MOBILE',
          autoPlaceholder: 'off',
          utilsScript: "https://cdn.jsdelivr.net/npm/intl-tel-input@23.0.10/build/js/utils.js"
        }}
        inputProps={{
          
          placeholder: placeholder,
          onBlur: onBlur,
          disabled: disabled,
        }}
        onChangeNumber={onChangeNumber}
        onChangeValidity={setPhoneNumberValidity}
      />
      {layout.error? <div className="warning">{layout.errorMessage?.length? layout.errorMessage: 'This is a required field.'}</div>: null}
    </PhoneNumberInputContainer>
  );
};

export default PhoneNumberInput;
