export const MAT_RATE = 0.06

export const HST_RATE = 0.13

export const DEFAULT_CHECK_IN_HOUR = 15

export const DEFAULT_CHECK_IN_MINUTE = 0

export const DEFAULT_CHECK_OUT_HOUR = 11

export const DEFAULT_CHECK_OUT_MINUTE = 0

export const DEFAULT_CUT_OFF_HOUR = 3

export const DEFAULT_CUT_OFF_MINUTE = 0

export const DEFAULT_MAX_DAYS = 90

export const bookingsStatuses = {
    CHECKED_IN: {
      className:'checkedIn',
      text: 'Checked in'
    },
    CHECKED_OUT: {
      className:'checkedOut',
      text: 'Checked out'
    },
    CONFIRMED: {
      className:'confirmed',
      text: 'Confirmed'
    },
    INCOMPLETE: {
      className:'incomplete',
      text: 'Incomplete'
    },
    CANCELLED: {
      className:'cancelled',
      text: 'Cancelled'
    },
    EXPIRED: {
      className:'expired',
      text: 'Expired'
    }
  }