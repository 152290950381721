import { useState } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userPhoneSubscriptionState } from 'states/CheckOutStates/atoms'
import { useMutation, useLazyQuery } from '@apollo/client';
import { DASHBOARD_CREATE_PHONE_SUBSCRIPTION, DASHBOARD_PHONE_SUBSCRIPTIONS_BY_USER, DASHBOARD_PHONE_UNSUBSCRIPTION, DASHBOARD_PHONE_RESUBSCRIPTION} from 'graphql/service';

/**
 * A custom hook for Personal details Phone number Screen to load and edit phone subscriptions
 */
export const usePhoneSubscription = () => {

  const debug = false;
  
  const userId = localStorage.getItem('userId');

  // 
// This is current phone subscription we use
//       "subscriptionTypes": [
// {
//     "id": "d8761bf0-dc42-4711-9aef-5c8f912bc982",
//     "type": "general phone",
//     "createdAt": "2024-11-21T11:26:38.852Z",
//     "updatedAt": "2024-11-21T11:26:38.853Z"
// }
//       ]

const generalSubscriptionTypeId = "d8761bf0-dc42-4711-9aef-5c8f912bc982";

  
  
  const userPhoneSubscription = useRecoilValue(userPhoneSubscriptionState);
  const setUserPhoneSubscriptionState = useSetRecoilState(userPhoneSubscriptionState);

  
  const [errorState, setErrorState] = useState({
    hasError: false,
    message: '',
  });

  const [suscessState, setSuscessState] = useState({
    isSuscess: false,
    message: '',
  });


  const [dashboardCreatePhoneSubscription] = useMutation(DASHBOARD_CREATE_PHONE_SUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('CreatePhoneSubscription data =', data);
      }
      setUserPhoneSubscriptionState((prevState)=>({
        ...prevState,
        subscriptionID: data.createPhoneSubscription.id,
        isUserSubscribed: true
      }));

      if (debug) {
        console.log ('!!! Phone subscription created !!!');
      };

      setSuscessState({
        isSuscess: true,
        message: "Now you will receive occasional marketing texts from Rook Hotel",
      });

    },
    onError: (error) => {
      const content = error.message;
      
      setErrorState({
        hasError: true,
        message: error.message,
      });

      if (debug) {
        console.log('CreatePhoneSubscription error = ', error);
      }
    },
  });

  const [dashboardPhoneUnsubscription] = useMutation(DASHBOARD_PHONE_UNSUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('PhoneUnsubscriptiondata =', data);
      }
      setUserPhoneSubscriptionState((prevState)=>({
        ...prevState,
        isUserSubscribed: false
      }));

      if (debug) {
        console.log ('!!! Phone unsubscribed !!!');
      }

      setSuscessState({
        isSuscess: true,
        message: "Now you will not receive occasional marketing texts from Rook Hotel",
      });
    },
    onError: (error) => {
      const content = error.message;

      setErrorState({
        hasError: true,
        message: error.message,
      });
      if (debug) {
        console.log('PhoneUnsubscription error = ', error);
      }
    },
  });

  const [dashboardPhoneResubscription] = useMutation(DASHBOARD_PHONE_RESUBSCRIPTION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('PhoneResubscription data =', data);
      }
      setUserPhoneSubscriptionState((prevState)=>({
        ...prevState,
        isUserSubscribed: true
      }));

      if (debug) {
        console.log ('!!! Phone Resubscription !!!');
      }

      setSuscessState({
        isSuscess: true,
        message: "Now you will receive occasional marketing texts from Rook Hotel",
      });

    },
    onError: (error) => {
      const content = error.message;
        setErrorState({
            hasError: true,
            message: error.message,
        });
      if (debug) {
        console.log(error);
      }
    },
  });

  const [fetchDashboardPhoneSubscriptionsByUser, { data, loading, error }] = useLazyQuery(
    DASHBOARD_PHONE_SUBSCRIPTIONS_BY_USER,
    {
      onCompleted: (data) => {
        if (debug) {
          console.log('Loaded data from server =', data);
        }
  
        // Logic for handling subscription data inside onCompleted
        if (data && data.phoneSubscriptionsByUser) {
          const activeSubscription = data.phoneSubscriptionsByUser.find(
            (subscription) =>
              !subscription.isCancelled &&
              subscription.subscriptionTypes.some(
                (type) => type.subscriptionType?.id === generalSubscriptionTypeId
              )
          );
  
          if (activeSubscription) {
            if (debug) {
              console.log('Active SubscriptionID = ' + activeSubscription.id);
            }
            setUserPhoneSubscriptionState((prevState)=>({
              ...prevState,
              subscriptionID: activeSubscription.id,
              isUserSubscribed: true,
              loadDataFromServer: false
            }));

            if (debug) {
              console.log ('!!! Active Phone Subscription loaded !!!');
            }
          } else {
            // If no active subscriptions matching the criteria or all are cancelled
            const firstInactiveSubscription = data.phoneSubscriptionsByUser.find(
              (subscription) => subscription.isCancelled&&
              subscription.subscriptionTypes.some(
                (type) => type.subscriptionType?.id === generalSubscriptionTypeId
              )
            );
            if (firstInactiveSubscription) {
                setUserPhoneSubscriptionState((prevState)=>({
                ...prevState,
                subscriptionID: firstInactiveSubscription.id, // Set the ID of the first inactive subscription for future resubscription 
                isUserSubscribed: false,
                loadDataFromServer: false
              }));

              if (debug) {
                console.log ('!!! first Inactive Subscription loaded !!!');
              }

              if (debug) {
                console.log('Inactive SubscriptionID = ' + (firstInactiveSubscription?.id || 'None'));
              }

            }
    
            // If no any subscriptions on server
            setUserPhoneSubscriptionState((prevState)=>({
              ...prevState,
              loadDataFromServer: false
            }));
          }
        }
      },
      onError: (error) => {
        const content = error.message;
        setErrorState({
            hasError: true,
            message: error.message,
          });
        if (debug) {
          console.log("fetchDashboardPhoneSubscriptionsByUser error = ", error);
        }
      },
    }
  );

  const handleFetchPhoneSubscriptionsByUser = () => {
    fetchDashboardPhoneSubscriptionsByUser({
        variables: {
          userId, 
        },
      });
  };


  const handleSubscription = () => {
    dashboardCreatePhoneSubscription({
      variables: {
        input: {
          userId: userId,
          subscriptionTypeIds: [generalSubscriptionTypeId]
        }
      },
    });
  };

  const handleReSubscription = () => {
    dashboardPhoneResubscription({
      variables: {
          id: userPhoneSubscription.subscriptionID
       },
    });
  };


  const handleUnSubscription = () => {
    dashboardPhoneUnsubscription({
      variables: {
          id: userPhoneSubscription.subscriptionID
      },
    });
  };

  return {
    handleFetchPhoneSubscriptionsByUser,
    handleSubscription,
    handleReSubscription,
    handleUnSubscription,
    errorState,
    setErrorState,
    suscessState,
    setSuscessState
  }
}

