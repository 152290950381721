import React, { FC } from 'react';
import { Email }  from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import { useState, useEffect, useCallback} from 'react';
import StyledButton from 'components/Button';
import Checkbox from 'components/Checkbox'
import AccountModal from "components/AccountModal";
import VerificationInputField from 'components/VerificationInputField'
import { useAuth } from 'customHooks/useAuth';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userInfoState, userEmailSubscriptionState} from 'states/CheckOutStates/atoms'
import { useMutation, useLazyQuery } from '@apollo/client';
import { DASHBOARD_SEND_EMAIL_VERIFICATION, DASHBOARD_CHANGE_EMAIL} from 'graphql/service';
import AlertMessage from 'components/AlertMessage';
import { useEmailSubscription } from 'customHooks/useEmailSubscription';

import {SendEmailVerificationInput, VerifyAccountInput} from "types/commonTypes";


// task RHCFW-226
// For updating user details in the first form use updateUser mutation.
// for changing email use sendEmailVerification and then  changeEmail mutation.
// for phoneNumber, use sendPhoneNumberVerification and then changePhoneNumber


// From Slack descriptiom from @Emmy 
//***
// createEmailSubscription
// mutation CreateEmailSubscription2 { createEmailSubscription(data: {email: "vadimka@vadimka.com", subscriptionTypeIds: ["5f705113-b914-4cee-b503-72c476ae27ee"]})}
// emailUnsubscription

// Vadim
//
// ok, lets start from simple questions, user chachek this checkbox, and clicks "save", what should I send to API? (edited) 
//Emmy
// createEmailSubscription
//Emmy
//The texts from checkboxes should be frome subscriptionType
//Emmy
//Baolong did something similar if can remember
//Vadim
//mutation CreateEmailSubscription2 { createEmailSubscription(data: {email: "vadimka@vadimka.com", subscriptionTypeIds: ["5f705113-b914-4cee-b503-72c476ae27ee"]})}
//this is correct?
//Emmy
//Yes
//Vadim
//ok, now user uncheck checkbox and hit save, what should I send?
//Emmy
//emailUnsubscription
//Vadim
//Where I can get this ID?
//Emmy
//This is got after subscrption
//Vadim
//and, how I can get this ID if user need to be unsubscribed for example tomorrow?
//Vadim
//also, once user come to this page, how can I set this checkbox, how can I check if user subscribed or not?
//Emmy
//emailSubscriptionsByEmail, will get you all subscriptions that's what you show as checked
//Emmy
//others from subscriptionTypes should be unchecked

//---------******----------
//@Vadim the subscription endpoints and input data changed, I also added endpoints for phone subscriptions.
//Here is a list of endpoints, by their names you can know what they do,
//subscriptionTypes, emailSubscriptionsByUser, phoneSubscriptionsByUser, createSubscriptionType, 
//createEmailSubscription, createPhoneSubscription, updateEmailSubscription, updatePhoneSubscription, 
//emailUnsubscription, phoneUnsubscription, emailUnsubscriptionReason, phoneUnsubscriptionReason

//Emmy: Actually, there is a emailReSubscription endpoint
// phoneReSubscription

//---------******----------





const EmailContent: FC = () => {
  const debug = true;





  const userId = localStorage.getItem('userId');

  const { refreshUserInfo } = useAuth();

  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);


  const setUserInfo = useSetRecoilState(userInfoState);
  const userInfo = useRecoilValue(userInfoState);


  
  useEffect(() => {
    setEmailValue(userInfo.emailInput);
    setNewEmailValue(userInfo.emailInput);
    
  }, [userInfo]);




  
  const [emailValue, setEmailValue] = useState(userInfo.emailInput);
  const [newEmailValue, setNewEmailValue] = useState(userInfo.emailInput);

  const [receiveMarketingEmails, setReceiveMarketingEmails] = useState(false);


  const [changeEmailMoad, setChangeEmailMoadl] = useState(false);
  const [changeConfirmationMoadal, setChangeConfirmationMoadal] = useState(false);

  const [newEmailError, setNewEmailError] = useState(false);
  const [newEmailErrorText, setNewEmailErrorText] = useState('');

  const [newVerificationCodeError, setNewVerificationCodeError] = useState(false);
  const [newVerificationCodeErrorText, setNewVerificationCodeErrorText] = useState('');

  const [saveSucsessMessage, setSaveSucsessMessage] = useState(false);
  const [saveSucsessMessageText, setSaveSucsessMessageText] = useState('Your e-mail has been successfully changed.');

  const [saveErrorMessage, setSaveErrorMessage] = useState(false);
  const [saveErrorMessageText, setSaveErrorMessageText] = useState('Your e-mail update failed!');

  


  const [codeResent, setCodeResent] = useState(false);
  
  const CODE_LENGTH = 5;

  const [verificationCode, setVerificationCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));

  
  // if (debug) {
  //   console.log("userInfo:");
  //   console.log(userInfo);
  // }

  function isValidEmail(email: string): boolean {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  }

  /**
 * Masks part of the email by replacing characters with asterisks.
 * @param email - The email address to be masked.
 * @returns The masked email address.
 */

function maskEmail(email: string): string {
  const [localPart, domain] = email.split("@");

  // If the local part is too short, do not mask it
  if (localPart.length <= 2) {
    return email; // Return the original email
  }

  // Mask the local part: keep the first and last characters visible
  const maskedLocalPart =
    localPart[0] + "*".repeat(localPart.length - 2) + localPart[localPart.length - 1];

  return `${maskedLocalPart}@${domain}`;
}



  const [dashboardSendEmailVerification] = useMutation(DASHBOARD_SEND_EMAIL_VERIFICATION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('data =');
          console.log (data);
      }
      setChangeEmailMoadl(false);
      setChangeConfirmationMoadal(true);
    },
    onError: (error) => {
      const content = error.message;

      setNewEmailErrorText(content);
      setNewEmailError(true);

      if (debug) {
        console.log(error);
      }
    },
  });


  const handleVerificationCodeChange = (newValues: string[]) => {
    setNewVerificationCodeError (false);
    setVerificationCode(newValues); 
  };


  const handleSendNewEmail = () => {
    let noErrors = true;

//    setSaveSucsessMessage(true);
    

    if (!isValidEmail(newEmailValue)) {
      setNewEmailErrorText("Please, enter correct e-mail.");
      setNewEmailError(true);
      noErrors = false;
    }

    if (noErrors) {
      setNewEmailErrorText('');
      setNewEmailError(false);

      setCodeResent(false);
      setVerificationCode([]);
      setNewVerificationCodeError(false);
      dashboardSendEmailVerification({
        variables: {
          input: {
            oldEmail: emailValue,
            newEmail: newEmailValue,
          },
        },
      });
    }
  };


  const handleReSendNewEmail = () => {

    setVerificationCode([]);
    setNewVerificationCodeError (false);
    setCodeResent (true);
      dashboardSendEmailVerification({
        variables: {
          input: {
            oldEmail: emailValue,
            newEmail: newEmailValue,
          },
        },
      });

  };



  const [dashboardChangeEmail] = useMutation(DASHBOARD_CHANGE_EMAIL, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('data =');
          console.log (data);
      }
      setChangeConfirmationMoadal(false);
      setSaveSucsessMessageText ("Your e-mail has been successfully changed.");
      setSaveSucsessMessage (true);
      setVerificationCode([]);
      setUserInfo((userInfo)=>({
        ...userInfo,
        emailInput: newEmailValue
      }))
    },
    onError: (error) => {
      const content = error.message;
      setChangeConfirmationMoadal(false);
      setVerificationCode([]);
      setNewEmailValue (emailValue);
      setSaveErrorMessageText(content);
      setSaveErrorMessage(true);
      if (debug) {
        console.log(error);
      }
    },
  });


  const handleChangeEmail = () => {
    let noErrors = true;

//    setSaveSucsessMessage(true);

if (debug) {
  console.log('Verification code:' + verificationCode);
}
  // converting verificationCode from string[] to string
  let verificationCodeString = verificationCode.join("");

  if (verificationCodeString.length < 5) {
    setNewVerificationCodeErrorText("Please, enter verification code.");
    setNewVerificationCodeError(true);
    noErrors = false;
  }

    if (noErrors) {
      setNewEmailErrorText('');
      setNewEmailError(false);


      dashboardChangeEmail({
        variables: {
          input: {
            email: newEmailValue,
            token: verificationCodeString,
          },
        },
      });
    }
  };

  // Verification code resizing based on window size:

  const [verificationInputInputHeight, setVerificationInputInputHeight] = useState("67px");
  const [verificationInputInputWidth, setVerificationInputInputWidth] = useState("63px");
  const [verificationInputMargin, setVerificationInputMargin] = useState("20px 0");

  useEffect(() => {
    // Function to update the size based on window width
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setVerificationInputInputHeight("40px");
        setVerificationInputInputWidth("40px");
        setVerificationInputMargin ("8px 0 22px 0");
      } else {
        setVerificationInputInputHeight("67px");
        setVerificationInputInputWidth("63px");
        setVerificationInputMargin ("20px 0");
      }
    };

    // Call the function initially to set the size when the component mounts
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


    //**** Email notifications preferences checkbox */
  
    const emailSubscriptionState = useRecoilValue(userEmailSubscriptionState);

    const {
      handleFetchEmailSubscriptionsByUser,
      handleSubscription,
      handleReSubscription,
      handleUnSubscription,
      errorState,
      setErrorState,
      suscessState,
      setSuscessState
    } = useEmailSubscription();
  
    useEffect (() => {
      setReceiveMarketingEmails (emailSubscriptionState.isUserSubscribed);
      if (debug){
        console.log ("current emailSubscriptionState", emailSubscriptionState);
      }
    }, [emailSubscriptionState.isUserSubscribed])
  
    useEffect(() => {
      if (emailSubscriptionState.loadDataFromServer) {
        handleFetchEmailSubscriptionsByUser();
      }
    }, [emailSubscriptionState.loadDataFromServer]);
  
    useEffect(() => {
      if (errorState.hasError){
        setSaveErrorMessageText (errorState.message);
        setSaveErrorMessage (true);
  
      }
    }, [errorState]);
  
    useEffect(() => {
      if (suscessState.isSuscess){
        setSaveSucsessMessageText (suscessState.message);
        setSaveSucsessMessage (suscessState.isSuscess);
  
      }
    }, [suscessState]);
  
  
    const updateEmailSubscriptionData = () => {
  
      if (debug) {
        console.log ('receiveEmailNotifications = '+ receiveMarketingEmails);
        console.log ('isUserSubscribed = '+ emailSubscriptionState.isUserSubscribed);
      }
  
       if (receiveMarketingEmails != emailSubscriptionState.isUserSubscribed) {
          if (receiveMarketingEmails) {
            if (emailSubscriptionState.subscriptionID !='') {
              // Resubscribe user 
              handleReSubscription ();
            }
            else {
              // Subscribe user 
              handleSubscription ();
            }
          }
          else {
            handleUnSubscription ();
          }
       }
    }
  
  //**** Email notifications preferences checkbox */


  return (
    <Email>

            <AlertMessage
                showMessage={saveSucsessMessage}
                //messageType = 'error'
                onClose={() => setSaveSucsessMessage(false)} // Changing state on close
            >
                {saveSucsessMessageText}
            </AlertMessage>
            
            <AlertMessage
                showMessage={saveErrorMessage}
                messageType = 'error'
                onClose={() => setSaveErrorMessage(false)} // Changing state on close
            >
                {saveErrorMessageText}
            </AlertMessage>

      {/* Change E-mail modal */}
      <AccountModal
        open={changeEmailMoad}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Enter New Email Address"
        onClose={() => setChangeEmailMoadl(false)}
      >
        <div className="accounModalBody emailModal">
          <div className="inpuntCont email">
            <FormInputField
              disabled={false}
              className="secInput"
              // highLightLabel='CURRENT '
              label="EMAIL ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              error={newEmailError}
              errorMessage={newEmailErrorText}
              value={newEmailValue}
              inputHeight="48px"
              marginBottom="20px"
               onChange={(e) => {
                setNewEmailError (false);
                setNewEmailValue(e.target.value)
                 }
               }
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => {
                handleSendNewEmail ();
              }}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              // onClick={handleResetPassword}
            >
              Continue
            </StyledButton>
          </div>
        </div>
      </AccountModal>

      {/* Verify email address modal */}
      <AccountModal
        open={changeConfirmationMoadal}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Confirmation Required"
        onClose={() => setChangeConfirmationMoadal(false)}
      >
        <div className="accounModalBody confirmationMoadal">
          <h4 className="confirmationModalHeader">Verify email address</h4>
          <div className="confirmationModalText">
            A verification code has been sent to your email address {maskEmail(newEmailValue)}. Please enter the code below to confirm your update:
          </div>
          <div className="inpuntCont confirmationModalInput">
            {/* <label>
              <span>VERIFICATION CODE</span>
              <span className="required"> *</span>
            </label> */}
            <VerificationInputField
              label="Verification Code"
              isRequired
              margin={verificationInputMargin}
              width="100%"
              length={CODE_LENGTH}
              values={verificationCode}
              setValues={handleVerificationCodeChange}
              inputHeight = {verificationInputInputHeight}
              inputWidth = {verificationInputInputWidth}
              error={newVerificationCodeError}
              errorMessage={newVerificationCodeErrorText}
              labelSize = "14px"
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="192px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                handleChangeEmail();
              }}
            >
              Verify Email Address
            </StyledButton>
          </div>
          <div className="bottomText">
            {!codeResent ? (
              <>
                Haven’t received the code?&nbsp;
                <a
                  className="resendCodeLink"
                  onClick={() => {
                    handleReSendNewEmail();
                  }}
                >
                  Resend code
                </a>
              </>
            ) : (
              <>Code Resent.</>
            )}
          </div>
        </div>
      </AccountModal>

      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
           Your email address has already been verified. If you wish to change
            your email address click the change button below.
          </div>
          <div className="inpuntCont email">
            <FormInputField
              disabled={true}
              className="secInput"
              // highLightLabel='CURRENT '
              label="EMAIL ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              // error={oldPasswordError}
              // errorMessage={errorMessageOldPass}
              value={emailValue}
              inputHeight="48px"
              marginBottom="20px"
              // onChange={(e) => {
              //   setOldPassword(e.target.value)
              //   setNewPasswordSucsess (false)
              //   if(oldPasswordError) {
              //     setOldPasswordError(false)
              //     setNewPasswordSucsess (false)
              //     setSowResetPasswordHint(false)
              //   }
              // }}
            />
          </div>
          <div className="changeButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setNewEmailError (false);
                setNewEmailValue(emailValue);
                setChangeEmailMoadl(true);
                if (debug) {
                  console.log(changeEmailMoad);
                  console.log(changeConfirmationMoadal);
                }
              }}
            >
              Change
            </StyledButton>
          </div>
        </div>
      </div>

      <div className="accountBody">
        <div className="cardContent">
          <h3>Email notification preferences</h3>
          <Checkbox
            className="accountCheckbox"
            checked={receiveMarketingEmails}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Receive occasional marketing emails from Rook Hotel."
            onChange={() => {
              setReceiveMarketingEmails(!receiveMarketingEmails);
            }}
            checkboxScale={1.2}
          />
          <div className="note">
            <strong>Note:</strong> this does not include account related emails,
            such as booking confirmations, or check-out receipts.
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={()=> {
                updateEmailSubscriptionData ();
              }}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </Email>
  );
};

export default EmailContent;
