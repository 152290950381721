import React, {
  useContext,
  createContext,
  FC,
  ReactNode,
  useState,
  useEffect,
  useMemo,
  useCallback
} from 'react';
import { useHistory } from 'react-router-dom';
import { useLazyQuery, useMutation } from '@apollo/client';
import { client } from 'graphql/apolloClient';
import { SIGN_IN, VERIFY_ACCOUNT, GET_USER_INFO_BY_USER_ID } from 'graphql/service';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { selectedRooms, userInfoState, paymentInfoState } from 'states/CheckOutStates/atoms'
import jwt_decode from "jwt-decode"
import { JsonWebToken } from 'types/commonTypes'

/**
 *  A custom hook for auth
 *  useAuth - useContext(AuthContext)
 *  AuthProvider - as top level container to wrap around the child component
 */
interface AuthContextType {
  validToken: string | null;
  userId: string | null;
  userSignIn: (email: string, password: string, redirectPath: string) => void;
  userVerification: (email: string, oneTimeToken: string) => void;  
  userLogout: () => void;
  // added to refresh user info for personal details pages
  refreshUserInfo: () => void;
}

const AuthContext = createContext<AuthContextType>({} as AuthContextType)

export const AuthProvider: FC<{ children: ReactNode | ReactNode[] }> = ({ children }) => {

  const history = useHistory();
  const setUserInfo = useSetRecoilState(userInfoState)
  const clearSelectedRooms = useResetRecoilState(selectedRooms)
  const clearUserInfo = useResetRecoilState(userInfoState)
  const clearPaymentInfo = useResetRecoilState(paymentInfoState)
  const [ validToken, setValidToken ] = useState(localStorage.getItem('token'))
  const [ userId, setUserId ] = useState(localStorage.getItem('userId'))

  const [getUserInfoByUserId] = useLazyQuery(GET_USER_INFO_BY_USER_ID,
    {
      onCompleted: (userInfoData)=> {
        if (userInfoData.userById.fullName) {
          setUserInfo((userInfo)=>({
            ...userInfo,
            userId: userInfoData.userById.userId,
            fullNameInput: userInfoData.userById.fullName,
            emailInput: userInfoData.userById.email,
            mailAddressInput: userInfoData.userById.address,
            phoneNumberInput: userInfoData.userById.phoneNumber,
            dateOfBirth:  userInfoData.userById.dateOfBirth
          }))
        } else {
          localStorage.clear()
          setUserId(null)
          setValidToken(null)
          history.push('/signIn')
        }
      },
      onError: (error) => {
        const content = error.message
        console.log(content)
      }
    }
  )

  const [signIn, { error }]  = useMutation(
    SIGN_IN,
    { 
      onCompleted: (signInData) => {
        const token = signInData.authenticate?.accessToken        
        const decoded: JsonWebToken = jwt_decode(token)
        if(decoded.user_type === 'STAFF') {
          const content = 'Unauthorized User Type!'
          console.log(content)
          userLogout()
        } else {
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('token', token)
          setUserId(decoded.sub)
          setValidToken(token)
          history.push('/account/dashboard')
        }
      },
      onError(error) {
        const content = error.message
        console.log(content)
      }
    }
  )

  const [verifyAccount]  = useMutation(
    VERIFY_ACCOUNT,
    { 
      onCompleted: (verificationData) => {
        const token = verificationData.verifyAccount?.accessToken        
        const decoded: JsonWebToken = jwt_decode(token)
        if(decoded.user_type === 'STAFF') {
          const content = 'Unauthorized User Type!'
          console.log(content)
          userLogout()
        } else {
          localStorage.setItem('userId', decoded.sub)
          localStorage.setItem('token', token)
          setUserId(decoded.sub)
          setValidToken(token)
        }
      },
      onError(error) {
        const content = error.message
        console.log(content)
      }
    }
  )

  useEffect(()=>{
    if(userId) {
      getUserInfoByUserId({ variables: { userId: userId } })
    }
  },[userId, getUserInfoByUserId])

  const userLogout = () => {

    localStorage.clear()
    client.clearStore()
    setValidToken(null)
    clearSelectedRooms()
    clearUserInfo()
    clearPaymentInfo()
    console.log('Log out successfully!')
    history.push('/')
  }

  /**
   * user signIn function to set userId, tenantId, and access token
   * to state and localStorage
   * @param {string} account - user email or phone number
   * @param {string} password - user password
   * @param {string} redirectPath  - after user successfully signIn, can redirect different path depending on where to use
   */
  
  const userSignIn = (account: string, password: string, redirectPath: string) => {

    signIn({
      variables: {
        input: {
          login: account,
          password: password
        }
      }
    })  
  }

  const userVerification = (email: string, oneTimeToken: string) => {

    verifyAccount({
      variables: {
        input: {
          email,
          token: oneTimeToken
        }
      }
    })

    if(error) {
      const content = 'Sign in failed, Please check out your email or password!'
      console.log(content)
    }
  }

  // added to refresh user info for personal details pages
  const refreshUserInfo = useCallback(() => {
    if (userId) {
      getUserInfoByUserId({ variables: { userId } });
    }
  }, [userId, getUserInfoByUserId]);


  const memoedValue = useMemo(
    () => ({
      validToken,
      userId,
      userSignIn,
      userVerification,
      userLogout,
      // added to refresh user info for personal details pages
      refreshUserInfo,
    }),
    [validToken, userId, userLogout, userSignIn, userVerification, refreshUserInfo]
  )


  return (
    <AuthContext.Provider value={memoedValue}>
      {children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): AuthContextType => {
  return useContext(AuthContext);
}

