import { useEffect, useState } from 'react';
import { Booking } from 'types/commonTypes';
import { useQuery } from '@apollo/client';
import { GET_BOOKINGS_BY_GROUP_ID } from 'graphql/service';
import { useParams } from 'react-router-dom';



export const useLoadBookingsByGroupID = () => {

  const debug = true;

  const [bookings, setBookings] = useState<Booking[]>()

  const { groupId } = useParams<{groupId: string}>()

  if (debug) {
    console.log ("groupId from useLoadBookingsByGroupID =", groupId);
  }

  useEffect(() => {
    if (debug) {
        console.log ("bookings from useLoadBookingsByGroupID =", bookings);
      }
  }, [bookings]);
  
  const { loading, error, refetch } = useQuery(
    GET_BOOKINGS_BY_GROUP_ID,
    {
      variables: { groupId },
      onCompleted: (data) => {
        setBookings([...data.bookingsByGroupId]);
      },
      onError: (error) => {
        const content = error.message
        console.log(content)
      }
    }
  )

  return {
    bookings,
    loading,
    error,
    refetch
  }
}
