import React, { FC } from 'react';
import { PhoneNumber }  from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import { useState, useEffect   } from 'react';
import StyledButton from 'components/Button';
import Checkbox from 'components/Checkbox'
import { userInfoState} from 'states/CheckOutStates/atoms'
import AccountModal from "components/AccountModal";
import VerificationInputField from 'components/VerificationInputField'
import PhoneNumberInput from 'components/PhoneNumberInput';
import { usePhoneSubscription } from 'customHooks/usePhoneSubscription';
import { userPhoneSubscriptionState} from 'states/CheckOutStates/atoms'
import { useSetRecoilState, useRecoilValue } from 'recoil';
import AlertMessage from 'components/AlertMessage';
import { useChangePhone } from 'customHooks/useChangePhone';
import { DragBox } from 'ol/interaction';
// task RHCFW-226
// For updating user details in the first form use updateUser mutation.
// for changing email use sendEmailVerification and then  changeEmail mutation.
// for phoneNumber, use sendPhoneNumberVerification and then changePhoneNumber


//---------******----------
//@Vadim the subscription endpoints and input data changed, I also added endpoints for phone subscriptions.
//Here is a list of endpoints, by their names you can know what they do,
//subscriptionTypes, emailSubscriptionsByUser, phoneSubscriptionsByUser, createSubscriptionType, 
//createEmailSubscription, createPhoneSubscription, updateEmailSubscription, updatePhoneSubscription, 
//emailUnsubscription, phoneUnsubscription, emailUnsubscriptionReason, phoneUnsubscriptionReason
//---------******----------



const PhoneNumberContent: FC = () => {



  const setUserInfo = useSetRecoilState(userInfoState);
  const userInfo = useRecoilValue(userInfoState);


  const debug = true;

  
  const [receivePhoneNotifications, setReceivePhoneNotifications] = useState(false);

  const [changePhoneMoad, setChangePhoneMoadl] = useState(false);
  const [changeConfirmationMoadal, setChangeConfirmationMoadal] = useState(false);

  //const [phoneNumber, setPhoneNumber] = useState(userInfo.phoneNumberInput);
  
  const [phoneNumber, setPhoneNumber] = useState('');
  const [newPhoneNumber, setNewPhoneNumber] = useState('');

  const CODE_LENGTH = 5;
  

  const [saveSucsessMessage, setSaveSucsessMessage] = useState(false);
  const [saveSucsessMessageText, setSaveSucsessMessageText] = useState('');

  const [saveErrorMessage, setSaveErrorMessage] = useState(false);
  const [saveErrorMessageText, setSaveErrorMessageText] = useState('');


  // Verification code resizing based on window size:

  const [verificationInputInputHeight, setVerificationInputInputHeight] = useState("67px");
  const [verificationInputInputWidth, setVerificationInputInputWidth] = useState("63px");
  const [verificationInputMargin, setVerificationInputMargin] = useState("20px 0");

  useEffect(() => {
    // Function to update the size based on window width
    const handleResize = () => {
      if (window.innerWidth < 500) {
        setVerificationInputInputHeight("40px");
        setVerificationInputInputWidth("40px");
        setVerificationInputMargin ("8px 0 22px 0");
      } else {
        setVerificationInputInputHeight("67px");
        setVerificationInputInputWidth("63px");
        setVerificationInputMargin ("20px 0");
      }
    };

    // Call the function initially to set the size when the component mounts
    handleResize();

    // Add event listener to listen for window resize
    window.addEventListener('resize', handleResize);

    // Clean up event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


//**** Change Phone */

const [verificationCode, setVerificationCode] = useState<string[]>(Array(CODE_LENGTH).fill(''));

const [newPhoneNumberErrorMessage, setNewPhoneNumberErrorMessage] = useState(false);
const [newPhoneNumberErrorMessageText, setNewPhoneNumberErrorMessageText] = useState('');

const [verificationCodeError, setVerificationCodeError] = useState(false);
const [codeResent, setCodeResent] = useState(false);


const {
  changePhoneErrorState,
  setChangePhoneErrorState,
  changePhoneSuscessState,
  setChangePhoneSuscessState,

  sendPhoneVerificationErrorState,
  setSendPhoneVerificationErrorState,
  sendPhoneVerificationSuscessState,
  setSendPhoneVerificationSuscessState,


  handleSendPhoneVerification,
  handleChangePhoneNumber
} = useChangePhone();

useEffect(() => {
  setPhoneNumber (userInfo.phoneNumberInput);
  setVerificationCode([]);
}, [userInfo.phoneNumberInput]);


  useEffect(() => {
    setNewPhoneNumberErrorMessage(false);
    setNewPhoneNumberErrorMessageText ("")
    if (debug) {
      console.log ('current newPhoneNumber', newPhoneNumber);
    }
  }, [newPhoneNumber]);


  useEffect(() => {

      setNewPhoneNumberErrorMessage(sendPhoneVerificationErrorState.hasError);
      setNewPhoneNumberErrorMessageText (sendPhoneVerificationErrorState.message);
  }, [sendPhoneVerificationErrorState]);

  useEffect(() => {
    if (sendPhoneVerificationSuscessState.isSuscess){
        setChangePhoneMoadl(false);
        setVerificationCode([]);
        setVerificationCodeError (false);
        setChangeConfirmationMoadal(true);
    }
  }, [sendPhoneVerificationSuscessState]);


  useEffect(() => {
    if (changePhoneSuscessState.isSuscess){
        setChangeConfirmationMoadal(false);
        setSaveSucsessMessage (true);
        setSaveSucsessMessageText (changePhoneSuscessState.message);
    }
  }, [changePhoneSuscessState]);

  useEffect(() => {
    if (changePhoneErrorState.hasError){
        setChangeConfirmationMoadal(false);
        setSaveErrorMessage(true);
        setSaveErrorMessageText(changePhoneErrorState.message);
    }
  }, [changePhoneErrorState]);



  const sendConfirmation = () =>  {
    if (/^[+][0-9]{8,14}$/.test(newPhoneNumber)) {

      if (newPhoneNumber !== phoneNumber){ 

        handleSendPhoneVerification (phoneNumber, newPhoneNumber);
        // setChangePhoneMoadl(false);
        // setChangeConfirmationMoadal(true);
      }
      else  {
        setNewPhoneNumberErrorMessage(true);
        setNewPhoneNumberErrorMessageText ("The phone you provided is the same as your current phone.")
      }
    }
      else {
        setNewPhoneNumberErrorMessage(true);
        setNewPhoneNumberErrorMessageText ("Please enter valid phone number.")
      }
  }


  const reSendConfirmation = () => {
   
    handleSendPhoneVerification (phoneNumber, newPhoneNumber);
    setCodeResent (true);

  };

  const sendVerificationCode = () => {
     if (/^[0-9]{5}$/.test(verificationCode.join(""))) {
        handleChangePhoneNumber (newPhoneNumber, verificationCode.join(""))
     }
     else {
        setVerificationCodeError (true);
     }
  }


//**** Change Phone */



  //**** Phone notifications preferences checkbox */
  
  const phoneSubscriptionState = useRecoilValue(userPhoneSubscriptionState);

  const {
    handleFetchPhoneSubscriptionsByUser,
    handleSubscription,
    handleReSubscription,
    handleUnSubscription,
    errorState,
    setErrorState,
    suscessState,
    setSuscessState
  } = usePhoneSubscription();

  useEffect (() => {
    setReceivePhoneNotifications (phoneSubscriptionState.isUserSubscribed);
    // if (debug){
    //   console.log ("current phoneSubscriptionState", phoneSubscriptionState);
    // }
  }, [phoneSubscriptionState.isUserSubscribed])

  useEffect(() => {
    if (phoneSubscriptionState.loadDataFromServer) {
      handleFetchPhoneSubscriptionsByUser();
    }
  }, [phoneSubscriptionState.loadDataFromServer]);

  useEffect(() => {
    if (errorState.hasError){
      setSaveErrorMessageText (errorState.message);
      setSaveErrorMessage (true);

    }
  }, [errorState]);

  useEffect(() => {
    if (suscessState.isSuscess){
      setSaveSucsessMessageText (suscessState.message);
      setSaveSucsessMessage (suscessState.isSuscess);

    }
  }, [suscessState]);


  const updatePhoneSubscriptionData = () => {

    if (debug) {
      console.log ('receivePhoneNotifications = '+ receivePhoneNotifications);
      console.log ('isUserSubscribed = '+ phoneSubscriptionState.isUserSubscribed);
    }

     if (receivePhoneNotifications != phoneSubscriptionState.isUserSubscribed) {
        if (receivePhoneNotifications) {
          if (phoneSubscriptionState.subscriptionID !='') {
            // Resubscribe user 
            handleReSubscription ();
          }
          else {
            // Subscribe user 
            handleSubscription ();
          }
        }
        else {
          handleUnSubscription ();
        }
     }
  }

//**** Phone notifications preferences checkbox */


  // Function for debug
  // const forcePhoneChange = () => {
  //   // changing phone
  //   setPhoneNumber("+9876543210");

  //   if (debug) {
  //       console.log ("trying to change phone");
  //   }
  // }

  return (
    <PhoneNumber>
            <AlertMessage
                showMessage={saveSucsessMessage}
                //messageType = 'error'
                onClose={() => setSaveSucsessMessage(false)} // Changing state on close
            >
                {saveSucsessMessageText}
            </AlertMessage>
            
            <AlertMessage
                showMessage={saveErrorMessage}
                messageType = 'error'
                onClose={() => setSaveErrorMessage(false)} // Changing state on close
            >
                {saveErrorMessageText}
            </AlertMessage>

      {/* Change Phone Number modal */}
      <AccountModal
        open={changePhoneMoad}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Enter New Phone Number"
        onClose={() => setChangePhoneMoadl(false)}
      >
        <div className="accounModalBody phoneModal">
          <div className="inpuntCont phone">
          {phoneNumber ? (
          <PhoneNumberInput
                  key={phoneNumber || "empty"} // refresh initialValue once phone changed
                  initialValue={phoneNumber}
                  className='phoneNumberInput'
                  label="PHONE"
                  isRequired={true}
                  labelPosition='left'
                  error={newPhoneNumberErrorMessage}
                  errorMessage= {newPhoneNumberErrorMessageText}
                  inputHeight="43px"
                  marginBottom={'20px'}
                  onChangeNumber={setNewPhoneNumber}
                />
                              ) : (
                <div className="phonePlaceHolder">&nbsp;&nbsp;</div> // 
              )}

          </div>
          <div className="continueButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => { sendConfirmation () }}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              // onClick={handleResetPassword}
            >
              Continue
            </StyledButton>
          </div>
        </div>
      </AccountModal>

      {/* Verify phone number modal */}
      <AccountModal
        open={changeConfirmationMoadal}
        mobile={false}
        opacity="80%"
        verticalCenterd
        top="0"
        title="Confirmation Required"
        onClose={() => setChangeConfirmationMoadal(false)}
      >
        <div className="accounModalBody confirmationMoadal">
          <h4 className="confirmationModalHeader">Verify phone number</h4>
          <div className="confirmationModalText">
          A verification code has been sent to your current phone number ending in {String(newPhoneNumber).slice(-4)}. Please enter the code below to confirm your update:          </div>
          <div className="inpuntCont confirmationModalInput">
            {/* <label>
              <span>VERIFICATION CODE</span>
              <span className="required"> *</span>
            </label> */}
            <VerificationInputField
              label="Verification Code"
              isRequired
              margin={verificationInputMargin}
              width="100%"
              length={CODE_LENGTH}
              values={verificationCode}
              setValues={setVerificationCode}
              inputHeight = {verificationInputInputHeight}
              inputWidth = {verificationInputInputWidth}
              labelSize = "14px"
              error = {verificationCodeError}
            />
          </div>
          <div className="continueButton">
            <StyledButton
              width="192px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {sendVerificationCode ()}}
            >
              Verify Phone Number
            </StyledButton>
          </div>
          <div className="bottomText">
          {!codeResent ? (
              <>
                Haven’t received the code?&nbsp;
                <a
                  className="resendCodeLink"
                  onClick={() => {
                    reSendConfirmation();
                  }}
                >
                  Resend code
                </a>
              </>
            ) : (
              <>Code Resent.</>
            )}
          </div>
        </div>
      </AccountModal>

      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
          To update your phone number, we'll send a five-digit verification code to verify the new phone number added to your account.
          </div>
          <div className="inpuntCont phone">
          {phoneNumber ? (
          <PhoneNumberInput
                  //value={phoneValue}
                  // value="+1234567890"
                  key={phoneNumber || "empty"}  // refresh initialValue once phone changed
                  initialValue={phoneNumber}
                  className='phoneNumberInput'
                  label="PHONE"
                  isRequired={true}
                  labelPosition='left'
                  //error={!phoneNumberValidity && phoneNumberError}
                  errorMessage='Please enter a valid phone number'
                  inputHeight="43px"
                  marginBottom={'20px'}
                  onChangeNumber={() => {}} // do nothing
                  disabled={true}
                  //setPhoneNumberValidity={setPhoneNumberValidity}
                  // onBlur={() => {
                  //   if(!phoneNumberValidity&&(phoneNumber.length<3)) {
                  //     setPhoneNumberError(true)
                  //   }
                  // }}
                />
              ) : (
                <div className="phonePlaceHolder">&nbsp;&nbsp;</div> // 
              )}
          </div>
          <div className="changeButton">
            <StyledButton
              width="99px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                setNewPhoneNumberErrorMessage(false);
                setChangePhoneMoadl(true);
              }}
            >
              Change
            </StyledButton>
          </div>
          <br /><br />
          <div className="changeButton">
            {/* 
            // Buttont for tests
            <StyledButton
              width="150px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={() => {
                forcePhoneChange();
              }}
            >
              Force Change
            </StyledButton> */}
          </div>
        </div>
      </div>

      <div className="accountBody">
        <div className="cardContent">
          <h3>Phone notification preferences</h3>
          <Checkbox
            className="accountCheckbox"
            checked={receivePhoneNotifications}
            //error={termsOfServiceError}
            borderColor={colors.labelGrey}
            label="Receive occasional marketing texts from Rook Hotel."
            onChange={() => {
              setReceivePhoneNotifications(!receivePhoneNotifications);
            }}
            checkboxScale={1.2}
          />
          <div className="note">
            <strong>Note:</strong> This does not include account related messages, such as booking confirmation, check in / check out confirmations, etc.
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              onClick={() => {
                updatePhoneSubscriptionData ();
              }}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </PhoneNumber>
  );
};

export default PhoneNumberContent;
