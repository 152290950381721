import styled from "styled-components";
import { BasicSectionContainer } from "../../../style";
import { styleSizes } from "constants/styleSizes";
import { colors } from "constants/colors";

export const Email = styled(BasicSectionContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  align-items: stretch;
  gap: 32px;

  .accounModalBody {
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-top: 34px;
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
          margin-top: 17px;
        }

    .continueButton {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
    }
    .bottomText {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      margin-top: 20px;
      a {
        color: ${colors.hoverBlue};
        font-weight: 600;
        text-decoration: underline;
      }
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        font-size: 13px;
        margin-top: 16px;
        }
    }
  }
  .confirmationMoadal {
      .confirmationModalHeader {
        margin: 0;
        padding: 0 0 20px 0;
        font-size: 24px;
        font-weight: 600;
        align-self: center;
        @media (max-width: ${styleSizes.mobileBreakpoint}) {
          font-size: 18px;
          padding: 0 0 15px 0;
        }
      }
      .confirmationModalText {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        text-align: center;
      }
      .confirmationModalInput {
        margin-top: 0;
        label {
          margin-bottom: 10px;
        }
      }
      .resendCodeLink {
      cursor: pointer;
      }
    }

  .cardContent {
    @media (max-width: ${styleSizes.mobileBreakpoint}) {
      -webkit-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      -moz-box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      box-shadow: 0px 1px 3px 0px ${colors.accounMenuBorderShadowColor};
      border: 1px solid ${colors.accounBorderColor};
      padding: 16px;
    }
    .topText {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 30px;
      line-height: 24px;
    }
    .inpuntCont {
      margin-bottom: 17px;
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        margin-bottom: 6px;
      }
      label {
        font-size: 14px;
        font-weight: 400;
        color: ${colors.secLabel};
      }
    }
    .email {
      max-width: 500px;
      input {
        background-color: ${colors.inputDisabled};
        color: ${colors.secLabel};
      }
    }
    h3 {
      margin-top: 0;
      padding: 0;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 30px;
    }
    .accountCheckbox {
      margin-bottom: 25px;
    }
    .note {
      margin-bottom: 29px;
      line-height: 24px;
    }
    .saveButton {
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
    .changeButton {
      @media (max-width: ${styleSizes.mobileBreakpoint}) {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }
    }
  }
`;
