import React, { FC, useEffect } from 'react';
import { PersonalDetails } from './style';
import FormInputField from 'components/FormInputField';
import { colors } from 'constants/colors';
import StyledButton from 'components/Button';
import Hint from 'components/Hint';
import { useState  } from 'react';
import { useSetRecoilState, useRecoilValue } from 'recoil';
import { userInfoState} from 'states/CheckOutStates/atoms'
import { useMutation } from '@apollo/client';
import { useAuth } from 'customHooks/useAuth';

import AlertMessage from 'components/AlertMessage';

import { DASHBOARD_UPDATE_PERSONAL_DETAILS_BY_USER_ID} from 'graphql/service';


// task RHCFW-226
// For updating user details in the first form use updateUser mutation.
// for changing email use sendEmailVerification and then  changeEmail mutation.
// for phoneNumber, use sendPhoneNumberVerification and then changePhoneNumber


const debug = true;


const PersonalDetailsContent: FC = () => {

  const formatDateToDDMMYYYY = (date: string): string => {
    if (date) {
      const parsedDate = new Date(date); 
      const day = String(parsedDate.getDate()).padStart(2, '0'); 
      const month = String(parsedDate.getMonth() + 1).padStart(2, '0'); 
      const year = parsedDate.getFullYear(); 
      return `${day}/${month}/${year}`; 
    }
    else {
      return ('');
    }
  };


  const formatDateToYYYYMMDD = (date: string): string => {
    const [day, month, year] = date.split('/'); 
    return `${year}-${month}-${day}`; 
  };


const userId = localStorage.getItem('userId');

const { refreshUserInfo } = useAuth();

useEffect(() => {
  refreshUserInfo(); 
}, [refreshUserInfo]); 

 const setUserInfo = useSetRecoilState(userInfoState);
 const userInfo = useRecoilValue(userInfoState); 

 useEffect(() => {
  setFullNameValue(userInfo.fullNameInput);
  setAddressValue(userInfo.mailAddressInput);
  setPhoneValue(userInfo.phoneNumberInput);
  // You might want to set dateOfBirth here if it's part of userInfo
  setDateOfBirth(formatDateToDDMMYYYY(userInfo.dateOfBirth) || ''); // default if not present
}, [userInfo]);

  if (debug) {
    console.log ("userInfo:");
    console.log (userInfo);
  }


  

  //const [isLoading, setIsLoading] = useState(true); // loading indicator
  const [fullNameValue, setFullNameValue] = useState(userInfo.fullNameInput);
  const [addressValue, setAddressValue] = useState(userInfo.mailAddressInput);
  const [phoneValue, setPhoneValue] = useState(userInfo.phoneNumberInput);

  

  const [dateOfBirth, setDateOfBirth] = useState(formatDateToDDMMYYYY(userInfo.dateOfBirth));
  const [isCleared, setIsCleared] = useState(false); // Indicator to track if the field has been cleared

  const [showHint, setShowHint] = useState<boolean>(false);


  const [saveSucsess, setSaveSucsess] = useState(false);
  
  const [saveSucsessMessage, setSaveSucsessMessage] = useState(false);
  
  const [saveSucsessMessageText, setSaveSucsessMessageText] = useState('Your personal details has been successfully changed.');

  const [saveErrorMessage, setSaveErrorMessage] = useState(false);
  const [saveErrorMessageText, setSaveErrorMessageText] = useState('User data update failed!');

  const [errorMessageFullName, setErrorMessageFullName] = useState(false);
  const [errorMessageFullNameText, setErrorMessageFullNameText] = useState('');

  const [errorMessageAddress, setErrorMessageAddress] = useState(false);
  const [errorMessageAddressText, setErrorMessageAddressText] = useState('');

  const [errorMessageDateOfBirth, setErrorMessageDateOfBirth] = useState(false);
  const [errorMessageDateOfBirthText, setErrorMessageDateOfBirthText] = useState('');



  const [dashboardUpdatePersonalDetailsByUserId] = useMutation(DASHBOARD_UPDATE_PERSONAL_DETAILS_BY_USER_ID, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('data =');
          console.log (data);
      }
      if (data.updateUser.userId) {
        setSaveSucsessMessageText("Your personal details has been successfully changed.");
        setSaveSucsessMessage(true);
        setUserInfo((userInfo)=>({
          ...userInfo,
          fullNameInput: data.updateUser.fullName,
          mailAddressInput: data.updateUser.address,
          dateOfBirth:  data.updateUser.dateOfBirth
        }))

        if (debug) {
          console.log("User data updated successfully!");
          console.log(data);
        }
      } else {
        const content = "User data update failed!";
        if (debug) {
          console.log(data);
        }
        setSaveErrorMessageText(content);
        setSaveErrorMessage(true);
      }
    },
    onError: (error) => {
      const content = error.message;

      setSaveErrorMessageText(content);
      setSaveErrorMessage(true);


      if (debug) {
        console.log(error);
      }
    },
  });


  const handleSavePersonalDetails = () => {
    let noErrors = true;

//    setSaveSucsessMessage(true);
    

    if (fullNameValue.length < 2) {
      setErrorMessageFullNameText("Please, enter correct Full name.");
      setErrorMessageFullName(true);
      noErrors = false;
    }
    if (addressValue.length < 2) {
      setErrorMessageAddressText("Please, enter correct address.");
      setErrorMessageAddress(true);
      noErrors = false;
    }
    if (dateOfBirth.length < 10) {
      setErrorMessageDateOfBirthText("Please, enter correct Date.");
      setErrorMessageDateOfBirth(true);
      noErrors = false;
    }

    if (noErrors) {
      setErrorMessageFullName(false);
      setErrorMessageAddress(false);
      setErrorMessageDateOfBirth(false);

      dashboardUpdatePersonalDetailsByUserId({
        variables: {
          userId,
          input: {
            fullName: fullNameValue,
            address: addressValue,
            dateOfBirth: formatDateToYYYYMMDD(dateOfBirth)
          },
        },
      });
    }
  };


  const handleMouseOver = () => {
    setShowHint(true);  
  };

  const handleMouseOut = () => {
    setShowHint(false); // Hide hint
  };



  const formatDate = (value: string) => {
    // Remove all non-digit characters
    value = value.replace(/[^\d]/g, '');

    // Add slashes after the 2nd and 5th characters
    if (value.length >= 2) value = value.slice(0, 2) + '/' + value.slice(2);
    if (value.length >= 5) value = value.slice(0, 5) + '/' + value.slice(5);

    // Limit the length to 10 characters (dd/mm/yyyy)
    return value.slice(0, 10);
  };

  const handleDateChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value;
    setDateOfBirth(formatDate(value)); // Format the date with slashes added
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (!isCleared) {
      // If the field has not been cleared, clear it and set the first entered character
      setIsCleared(true); // Mark that the field has been cleared
      setDateOfBirth(formatDate(e.key)); // Set the first entered character with slashes added
      e.preventDefault(); // Prevent default input to avoid adding the character twice
    }
  };

  const handleFocus = () => {
    setIsCleared(false); // Reset the clearing state when focused
  };

  return (
    <PersonalDetails>
            <AlertMessage
                showMessage={saveSucsessMessage}
                //messageType = 'error'
                onClose={() => setSaveSucsessMessage(false)} // Changing state on close
            >
                {saveSucsessMessageText}
            </AlertMessage>

            <AlertMessage
                showMessage={saveErrorMessage}
                messageType = 'error'
                onClose={() => setSaveErrorMessage(false)} // Changing state on close
            >
                {saveErrorMessageText}
            </AlertMessage>

      <div className="accountBody">
        <div className="cardContent">
          <div className="topText">
            It is important to review and verify that all details are correct
            and current. Keeping your information accurate ensures you receive
            important updates and notifications without any issues. Please make
            any necessary updates to reflect your latest contact information.
          </div>
          <div className="inpuntCont fullName">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="FULL NAME"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              error={errorMessageFullName}
              errorMessage={errorMessageFullNameText}
              // value={oldPassword}
              inputHeight="48px"
              marginBottom="20px"
              value={fullNameValue}
              maxLength={300}
              onChange={(e) => {
                setFullNameValue(e.target.value);
              }}
            />
          </div>
          <div className="inpuntCont address">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="ADDRESS"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              error={errorMessageAddress}
              errorMessage={errorMessageAddressText}
              value={addressValue}
              inputHeight="48px"
              marginBottom="20px"
              onChange={(e) => {
                setAddressValue(e.target.value);
              }}
              // onChange={(e) => {
              //   setOldPassword(e.target.value)
              //   setNewPasswordSucsess (false)
              //   if(oldPasswordError) {
              //     setOldPasswordError(false)
              //     setNewPasswordSucsess (false)
              //     setSowResetPasswordHint(false)
              //   }
              // }}
            />
          </div>
          <div className="inpuntCont dateOfBirth">
            <FormInputField
              className="secInput"
              // highLightLabel='CURRENT '
              label="DATE OF BIRTH (dd/mm/yyyy)"
              isRequired
              type="text"
              borderColor={colors.signInBorderColor}
              error={errorMessageDateOfBirth}
              errorMessage={errorMessageDateOfBirthText}
              value={dateOfBirth}
              onChange={handleDateChange}
              onClick={handleFocus}
              onFocus={handleFocus}
              onKeyDown={handleKeyDown} // Clear the field on the first input
              onMouseOver={handleMouseOver} 
              onMouseOut={handleMouseOut}
              // maxLength={11}

              inputHeight="48px"
              marginBottom="20px"
              infoIcon
            />
            {/* <InfoCircle className="infoIcon"></InfoCircle> */}
            {showHint? (
            <Hint
              title="Why do you need this?"
              message="To stay at our hotel, the primary guest must be 19+ years old."

              width="446"
              arrowPosition="22"
              positionBottom="88"
              positionLeft="200"

              widthTablet="446"
              arrowPositionTablet="223"
              positionBottomTablet="88"
              positionLeftTablet="0"

              widthMini="446"
              arrowPositionMini="223"
              positionBottomMini="88"
              positionLeftMini="-10"

              widthMobile="325"
              arrowPositionMobile="223"
              positionBottomMobile="88"
              positionLeftMobile="0"

            />
            ):null}
          </div>
          <div className="saveButton">
            <StyledButton
              width="81px"
              height="48px"
              //backgroundColor = {colors.signInBorderColor}
              //fontColor = {colors.white}
              disabledBackgroundColor={colors.signInBorderColor}
              disabledColor={colors.white}
              // disabled={oldPassword.length < 8 || newPassword.length < 8}
              onClick={handleSavePersonalDetails}
            >
              Save
            </StyledButton>
          </div>
        </div>
      </div>
    </PersonalDetails>
  );
};

export default PersonalDetailsContent;
