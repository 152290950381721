import { useState, useEffect } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { userPhoneSubscriptionState } from 'states/CheckOutStates/atoms'
import { useMutation, useLazyQuery } from '@apollo/client';
import { DASHBOARD_SEND_PHONE_VERIFICATION, DASHBOARD_CHANGE_PHONE} from 'graphql/service';
import { userInfoState} from 'states/CheckOutStates/atoms'
import { useAuth } from 'customHooks/useAuth';

/**
 * A custom hook for Personal details Phone number Screen to change phone number
 */


export const useChangePhone = () => {

  const debug = true;
  
  const userId = localStorage.getItem('userId');
  
  const { refreshUserInfo } = useAuth();


  useEffect(() => {
    refreshUserInfo();
  }, [refreshUserInfo]);

  const setUserInfo = useSetRecoilState(userInfoState);
  const userInfo = useRecoilValue(userInfoState);

  if (debug) {
    console.log ("userInfo from useChangePhone", userInfo);
  }
  
  const [changePhoneErrorState, setChangePhoneErrorState] = useState({
    hasError: false,
    message: ''
  });


  const [changePhoneSuscessState, setChangePhoneSuscessState] = useState({
    isSuscess: false,
    message: 'Phone was successfully changed.',
  });


  const [sendPhoneVerificationErrorState, setSendPhoneVerificationErrorState] = useState({
    hasError: false,
    message: ''
  });


  const [sendPhoneVerificationSuscessState, setSendPhoneVerificationSuscessState] = useState({
    isSuscess: false,
    message: '',
  });


  const [sendPhoneVerification] = useMutation(DASHBOARD_SEND_PHONE_VERIFICATION, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('handleSendPhoneVerification data =', data);
      }

      setSendPhoneVerificationSuscessState((prevState)=>({
        ...prevState,
        isSuscess: true,
        message: "Phone verefication sent"
      }));

    },
    onError: (error) => {
      const content = error.message;
      
      setSendPhoneVerificationErrorState((prevState)=>({
        ...prevState,
        hasError: true,
        message: content
      }));


      if (debug) {
        console.log("handleSendPhoneVerification error =", error);
      }
    },
  });


  const [changePhoneNumber] = useMutation(DASHBOARD_CHANGE_PHONE, {
    onCompleted: (data) => {
      if (debug) {
          console.log ('handleSendPhoneVerification data =', data);
      }

      setUserInfo((userInfo)=>({
        ...userInfo,
        phoneNumberInput: data.changePhoneNumber.phoneNumber
      }))

      setChangePhoneSuscessState((prevState)=>({
        ...prevState,
        isSuscess: true,
        message: "Phone was successfully changed."
      }));

    },
    onError: (error) => {
      const content = error.message;
      
      setChangePhoneErrorState((prevState)=>({
        ...prevState,
        hasError: true,
        message: content
      }));

      if (debug) {
        console.log("handleSendPhoneVerification error =", error);
      }
    },
  });


  const handleSendPhoneVerification = (oldPhoneNumber, newPhoneNumber) => {
    sendPhoneVerification({
      variables: {
        input: {
            oldPhoneNumber: oldPhoneNumber, 
            newPhoneNumber: newPhoneNumber
        }
       },
    });
  };

  const handleChangePhoneNumber = (phoneNumber, token) => {
    changePhoneNumber({
      variables: {
        input: {
            phoneNumber: phoneNumber, 
            token: token
        }
       },
    });
  };

  return {
    
    changePhoneErrorState,
    setChangePhoneErrorState,
    changePhoneSuscessState,
    setChangePhoneSuscessState,

    sendPhoneVerificationErrorState,
    setSendPhoneVerificationErrorState,
    sendPhoneVerificationSuscessState,
    setSendPhoneVerificationSuscessState,

    handleSendPhoneVerification,
    handleChangePhoneNumber
  }
}

